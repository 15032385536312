<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div>
        <h6 class="title-h6">{{$t('processes.candidat')}}</h6>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
              <base-input-text class="col" placeholder="Renseigner" v-bind="formInputProps('nom')" :color="color"
                v-model="formData.nom" ref="label" />
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.validation_state')}}</h6>
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-select class="q-mt-none col" v-bind="formInputProps('avis_rh')" :color="color"
                  v-model="formData.avis_rh" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_rdv_rh')" :color="color"
                  v-model="formData.date_rdv_rh" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-select :options="booleanOption" class="q-mt-none col" v-bind="formInputProps('validation_dcr')" :color="color"
                  v-model="formData.validation_dcr" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_validation_dcr')" :color="color"
                  v-model="formData.date_validation_dcr" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-select :options="booleanOption" class="q-mt-none col" v-bind="formInputProps('validation_dir_comm')" :color="color"
                  v-model="formData.validation_dir_comm" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_validation_dir_comm')" :color="color"
                  v-model="formData.date_validation_dir_comm" ref="label" />
              </div>
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-pa-sm q-mt-md">
          <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <div class="col q-mt-none">
                  <q-item-label class="">{{$t('candidat.fields.signature_lettre_agrement_impossible.label')}}</q-item-label>
                  <q-toggle class="" v-model="formData.signature_lettre_agrement_impossible" ref="is_red"
                    :color="color"
                  />
                </div>
                <base-input-date class="col q-mt-none" v-bind="formInputProps('date_signature_lettre_agrement')" :color="color"
                  v-model="formData.date_signature_lettre_agrement" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-select :options="sessionStageOption" class="q-mt-none col" v-bind="formInputProps('session_stage_id')" :color="color"
                  v-model="formData.session_stage_id" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_stage')" :color="color"
                  v-model="formData.date_stage" ref="label" />
              </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.nomination')}}</h6>
        <q-card flat bordered class="q-pa-sm row">
          <q-card-section class="col col-6">
            <base-input-date class="" v-bind="formInputProps('date_previsionnelle_nomination')" :color="color"
              v-model="formData.date_previsionnelle_nomination" ref="label" />
          </q-card-section>
        </q-card>
      </div>
    </div>
    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
        </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click="destroy" :disabled="!$route.params.id">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { BaseForm } from '../../../mixins'
import { mapGetters } from 'vuex'

export default {
  mixins: [BaseForm],
  props: {
    'form_model': { type: String, default: 'candidat' },
    'action': { type: String, default: 'candidat/saveCandidat' }
  },
  data () {
    return {
      linkedProcess: this.$route.meta.process,
      linkedProcessId: this.$route.params.processId,
      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ]
    }
  },
  methods: {
    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('candidat/deleteCandidat', this.$route.params.id).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  },
  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('candidat/fetchCurrentCandidat', currentRoute.params.id).then(() => {
        store.dispatch('candidat/fetchCandidatFormFields')
      })
    } else {
      store.dispatch('candidat/fetchCandidatFormFields')
    }

    store.dispatch('candidat/fetchSessionStage')
  },
  beforeDestroy () {
    this.$store.commit('candidat/setCurrentCandidat', null)
  },
  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_de_recrutement'), to: { name: 'suivi-de-recrutement-en-cours' } },
      { label: `fiche #${this.linkedProcessId}`, to: { name: 'suivi-de-recrutement-form', params: { id: this.linkedProcessId } } },
      { label: 'Candidat' }
    ])
    this.$store.commit('pages/setTabs', [
      this.$route.params.id
        ? { name: 'candidat-fiche', label: 'Candidats', to: { name: 'candidat-fiche', params: { id: this.$route.params.id } } }
        : { name: 'suivi-de-recrutement-form-candidat', label: 'Candidats', to: { name: 'suivi-de-recrutement-form-candidat', params: { } } }
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_recrutement'))
    if (this.linkedProcessId) {
      this.formData.related_objects = []
      this.formData.related_objects.push({
        model_type: this.linkedProcess,
        model_id: this.linkedProcessId
      })
    }
  },
  watch: {
    formFields: {
      handler (newVal, oldVal) {
        if (!newVal) return
        if (!this.currentCandidat) {
          // Set default value for statut
          this.formData.statut = {}
          this.formData.statut.label = 'candidat.fields.statut.options.CANDIDAT'
          this.formData.statut.value = 'CANDIDAT'

          return
        }

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type }) => ({ id, options, type }))
            .reduce((acc, { id, options, type }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentCandidat[id])
                : typeof this.currentCandidat[id] === 'boolean' && type === 'select'
                  ? this.booleanOption.find(val => val.value === this.currentCandidat[id])
                  : this.currentCandidat[id]
            }), {})
        }

        // Exception for session stage
        this.formData.session_stage_id = this.sessionStageOption.find(session => session.value === this.currentCandidat.session_stage_id)

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      sessionStage: 'candidat/getSessionStage',
      currentCandidat: 'candidat/getCurrentCandidat'
    }),
    sessionStageOption () {
      return this.sessionStage.map(session => ({
        label: `${this.$formatDate(session.date_debut)} - ${this.$formatDate(session.date_fin)}`,
        value: session.id
      }))
    }
  }
}
</script>
